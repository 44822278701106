import { Action } from '../actions'
import { Rate } from '../../domains/rate'

export type State = {
  rates: null | { type: 'Loading' } | { type: 'LoadingSuccess'; data: Rate[] } | { type: 'LoadingFail'; error: string }
}

const initialState: State = {
  rates: null,
}

export default (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case 'LOAD_DATA':
      return { ...state, rates: { type: 'Loading' } }
    case 'LOAD_DATA_SUCCESS':
      return { ...state, rates: { type: 'LoadingSuccess', data: action.payload } }
    case 'LOAD_DATA_FAILED':
      return { ...state, rates: { type: 'LoadingFail', error: action.payload } }
    default:
      return state
  }
}
