import { Action } from '../actions'
import { getParameterByName, checkPartner } from '../../utils'

export type State = {
  partnerId: null | string
  isPartnerAuthorized: boolean
  zoom: number
  lat: number
  lng: number
  bearing: number
  pitch: number
}

const decodedPartnerId = getParameterByName('partnerId')
const decodedZoom = getParameterByName('zoom')
const decodedLat = getParameterByName('lat')
const decodedLng = getParameterByName('lng')
const decodedBearing = getParameterByName('bearing')
const decodedPitch = getParameterByName('pitch')

const fallbackZoom = 13
const fallbackLat = 44.787
const fallbackLng = 20.458
const fallbackBearing = 55
const fallbackPitch = 60

const initialState: State = {
  partnerId: decodedPartnerId,
  isPartnerAuthorized: checkPartner(decodedPartnerId),
  zoom: decodedZoom ? parseInt(decodedZoom, 10) : fallbackZoom,
  lat: decodedLat ? parseFloat(decodedLat) : fallbackLat,
  lng: decodedLng ? parseFloat(decodedLng) : fallbackLng,
  bearing: decodedBearing ? parseInt(decodedBearing) : fallbackBearing,
  pitch: decodedPitch ? parseInt(decodedPitch) : fallbackPitch,
}

export default (state: State = initialState, action: Action): State => {
  switch (action.type) {
    default:
      return state
  }
}
