import { createAction, ActionType } from 'typesafe-actions'
import { Rate } from '../../domains/rate'

export type LoadDataPayload = { zoom: number; lat: number; lng: number }

export const loadData = createAction('LOAD_DATA', (payload: LoadDataPayload) => payload)()

export const loadDataSuccess = createAction('LOAD_DATA_SUCCESS', (payload: Rate[]) => payload)()

export const loadDataFail = createAction('LOAD_DATA_FAILED', (error: string) => error)()

const actions = {
  loadData,
  loadDataSuccess,
  loadDataFail,
}

export type Action = ActionType<typeof actions>
