import { put, takeLatest, call, select } from 'redux-saga/effects'
import { ActionType, getType } from 'typesafe-actions'

import { RootState } from '../reducers'
import * as actions from '../actions'
import request from '../../utils/request'
import { Rate } from '../../domains/rate'

export default function* saga() {
  yield call(onAppInit)
  yield takeLatest(getType(actions.loadData), onLoadData)
}

function* onAppInit() {
  const { partnerId } = yield select((state: RootState) => state.session)
  if (partnerId == null && process.env.NODE_ENV === 'development') {
    const queryString = window.location.search
    const sufix = queryString ? `${queryString}&partnerId=HowNiceIs` : '?partnerId=HowNiceIs'
    window.location.replace(`${window.location.origin}/${sufix}`)
  }
}

function* onLoadData(action: ActionType<typeof actions.loadData>) {
  const { zoom, lat, lng } = action.payload
  const url = `https://api.howniceis.com/api/v1/rate/list/${lat}/${lng}/${zoom}`
  const options = {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN as string}`,
    },
  }
  try {
    const data: Rate[] = yield call(request, url, options)
    yield put(actions.loadDataSuccess(data))
  } catch (error) {
    console.error(error)
    yield put(actions.loadDataFail(error.message))
  }
}
