import { combineReducers } from 'redux'

import base from './base'
import session from './session'

const reducers = {
  base,
  session,
}

const rootReducer = combineReducers(reducers)

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
