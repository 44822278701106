import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import 'mapbox-gl/dist/mapbox-gl.css'

import { RootState } from '../../redux/reducers'
import Map from '../map'
import './index.css'

interface StateProps {
  partnerId: string | null
  isPartnerAuthorized: boolean
  zoom: number
  lat: number
  lng: number
  bearing: number
  pitch: number
}

interface DispatchProps {}

interface OwnProps {}

type Props = StateProps & DispatchProps & OwnProps

const App = ({ partnerId, isPartnerAuthorized, zoom, lat, lng, bearing, pitch }: Props) => {
  const [windowDimensions, setWindowDimensions] = useState([window.innerWidth, window.innerHeight])

  useEffect(() => {
    const handler = () => {
      setWindowDimensions([window.innerWidth, window.innerHeight])
    }

    handler()
    window.addEventListener('resize', handler)
    return () => window.removeEventListener('resize', handler)
  }, [])

  let errorMessage: null | string = null
  if (partnerId != null) {
    errorMessage = !isPartnerAuthorized ? 'Partner is not authorized' : null
  } else {
    errorMessage = 'PartnerId is not provided'
  }
  return (
    <div className="app_wrapper">
      {errorMessage ? (
        <p>{errorMessage}</p>
      ) : (
        <Map
          width={windowDimensions[0]}
          height={windowDimensions[1]}
          initialLat={lat}
          initialLng={lng}
          initialZoom={zoom}
          initialBearing={bearing}
          initialPitch={pitch}
        />
      )}
    </div>
  )
}

const mapStateToProps = (state: RootState, ownProps: OwnProps): Props => ({
  partnerId: state.session.partnerId,
  isPartnerAuthorized: state.session.isPartnerAuthorized,
  zoom: state.session.zoom,
  lat: state.session.lat,
  lng: state.session.lng,
  bearing: state.session.bearing,
  pitch: state.session.pitch,
})

const withConnect = connect(mapStateToProps)

export default withConnect(App)
