import * as React from 'react'
import ReactMapGL, { NavigationControl, FullscreenControl, ScaleControl } from 'react-map-gl'

import './index.css'
// import testData from './testData.json'

// const geoJSON = {
//   'geojson-marker': {
//     type: 'geojson',
//     data: {
//       type: 'Feature',
//       geometry: {
//         type: 'Point',
//         coordinates: [-77.0323, 38.9131],
//       },
//       properties: {
//         title: 'Mapbox DC',
//         'marker-symbol': 'monument',
//       },
//     },
//   },
// }

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN || ''
const MAP_STYLE = 'mapbox://styles/howniceis/ck6180ann05o61it7d8rj7oxr'

type Viewport = { zoom: number; latitude: number; longitude: number; pitch: number; bearing: number }

type Props = {
  width: number
  height: number
  initialLat: number
  initialLng: number
  initialZoom: number
  initialPitch: number
  initialBearing: number
}

const Map = ({ width, height, initialLat, initialLng, initialZoom, initialPitch, initialBearing }: Props) => {
  const [viewport, setViewport] = React.useState<Viewport>({
    zoom: initialZoom,
    latitude: initialLat,
    longitude: initialLng,
    pitch: initialPitch,
    bearing: initialBearing,
  })

  const updateViewport = (v: Viewport) => {
    // console.log('new viewport', v)
    setViewport({ ...viewport, ...v })
  }

  return (
    <ReactMapGL
      {...viewport}
      width={width}
      height={height}
      mapboxApiAccessToken={MAPBOX_TOKEN}
      mapStyle={MAP_STYLE}
      onViewportChange={updateViewport}
    >
      <div className="map__controls__wrapper">
        <div className="map__fullscreen_control__wrapper">
          <FullscreenControl />
        </div>
        <NavigationControl />
      </div>
      <div className="map__scale_control__wrapper">
        <ScaleControl />
      </div>
    </ReactMapGL>
  )
}

export default Map
