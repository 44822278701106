export class ResponseError extends Error {
  public response: Response

  constructor(response: Response) {
    super(response.statusText)
    this.response = response
  }
}

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response: Response) {
  return response.json()
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response: Response): Response {
  if (response.status >= 200 && response.status < 300) {
    return response
  }

  throw new ResponseError(response)
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           An object containing either "data" or "err"
 */
export default function request(url: string, options?: RequestInit): Promise<{} | { err: ResponseError }> {
  return fetch(url, options)
    .then(checkStatus)
    .then(parseJSON)
    .then(json => json.data)
    .catch(err => {
      throw new ResponseError(err)
    })
}
